<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pt-3">
      <v-card-title class="mb-5 text-h5 font-weight-black">
        PAGO DE SERVICIO
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <icon-close class="icon--close"></icon-close>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="error" outlined border="left" type="warning">
          {{ errorMessage }}
        </v-alert>
        <div class="black--text mb-3">
          <p class="ma-0 text-caption">Fecha de pago</p>
          <p class="ma-0 text-uppercase font-weight-black text-subtitle-1">
            {{
              new Intl.DateTimeFormat("es-MX", { dateStyle: "medium" }).format(
                new Date()
              )
            }}
          </p>
        </div>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="black--text mt-3">
            <p class="ma-0 text-caption">Periodo a pagar</p>
            <p class="ma-0 text-uppercase font-weight-black text-subtitle-1">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :placeholder="label"
                    append-icon="mdi-calendar"
                    v-model="dateFormat"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :rules="[prueba]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  type="month"
                  :multiple="multiple"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </p>
            <p>
              <v-checkbox
                hide-details
                v-model="multiple"
                label="Pagar meses por adelantado"
              ></v-checkbox>
            </p>
          </div>
          <v-divider></v-divider>
          <div class="black--text mb-3 mt-3">
            <p class="ma-0 text-caption">Monto</p>
            <p class="ma-0 text-uppercase font-weight-black text-subtitle-1">
              <v-text-field
                :placeholder="placheHolderMount"
                :prefix="!multiple ? '$' : ''"
                v-model.number="mount"
                type="number"
                outlined
                :rules="mountRules"
              >
              </v-text-field>
            </p>
          </div>
        </v-form>
        <v-divider></v-divider>
        <div class="px-16 mt-5">
          <p class="black--text text-center ma-0">
            Antes de continuar, verifique con el cliente que todos los datos
            sean correctos.
          </p>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end mt-8 pb-8">
        <v-btn class="px-15 py-5" color="primary" depressed @click="toPay">
          PAGAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PaymentService",
  mounted() {
    if (!this.isEdit) this.min = new Date().toISOString().substr(0, 7);
  },
  components: {
    IconClose: () => import("../../icons/IconClose"),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    paymentData: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    valid: true,
    date: new Date().toISOString().substr(0, 7),
    min: "",
    dateFormat: new Intl.DateTimeFormat("es-MX", {
      year: "numeric",
      month: "long",
    })
      .format(new Date())
      .toString()
      .toUpperCase(),
    modal: false,
    multiple: false,
    label: null,
    placheHolderMount: "",
    mount: 250,
    error: false,
    errorMessage: "",
    mountRules: [
      (v) => !!v || "Campo requerido",
      // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
  }),
  methods: {
    toPay() {
      const payment = {};

      if (!this.$refs.form.validate()) return false;

      if (!this.multiple) {
        payment.start = null;
        payment.end = this.date;
      }

      if (this.multiple) {
        const limit = this.date.length - 1;
        payment.start = this.date[0];
        payment.end = this.date[limit];
      }

      payment.mount = this.mount;

      if(this.isEdit) {
        payment.idPlanHome = this.paymentData.idPlanHome;
        payment.idClient = this.paymentData.idClient;
        this.$emit("updatePayment", payment);
        return true;
      }

      this.$emit("toPay", payment);
    },
    prueba() {
      if (this.multiple && this.date.length < 2)
        return "Necesita elegir al menos dos fechas";

      return true;
    },
  },
  watch: {
    date: function () {
      if (!this.multiple) {
        const date = new Date(
          this.date.split("-")[0],
          this.date.split("-")[1] - 1,
          1
        );

        this.dateFormat = new Intl.DateTimeFormat("es-MX", {
          year: "numeric",
          month: "long",
        })
          .format(date)
          .toString()
          .toUpperCase();
      }

      if (this.multiple && this.date.length > 1) {
        const dates = [...this.date].sort();
        const init = new Date(
          dates[0].split("-")[0],
          dates[0].split("-")[1] - 1,
          1
        );
        const end = new Date(
          dates[dates.length - 1].split("-")[0],
          dates[dates.length - 1].split("-")[1] - 1,
          1
        );
        this.dateFormat =
          new Intl.DateTimeFormat("es-MX", {
            year: "numeric",
            month: "long",
          })
            .format(init)
            .toString()
            .toUpperCase() +
          " - " +
          new Intl.DateTimeFormat("es-MX", {
            year: "numeric",
            month: "long",
          })
            .format(end)
            .toString()
            .toUpperCase();
      }
    },
    multiple: function () {
      if (this.multiple) {
        this.label = "Seleccione los meses por pagar";
        this.dateFormat = "";
        this.mount = null;
        this.placheHolderMount = "Ingrese el monto a pagar";
        this.date = [];
        this.date.push(new Date().toISOString().substr(0, 7));
      }

      if (!this.multiple) {
        this.date = new Date().toISOString().substr(0, 7);
        const date = new Date(
          this.date.split("-")[0],
          this.date.split("-")[1] - 1,
          1
        );

        this.dateFormat = new Intl.DateTimeFormat("es-MX", {
          year: "numeric",
          month: "long",
        })
          .format(date)
          .toString()
          .toUpperCase();
        this.label = null;
        this.mount = 250;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon--close {
  width: 1.3rem;
}
</style>